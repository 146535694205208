<template>
  <div>
    <CModalExtended
      size="lg"
      :title="tituloModal"
      color="dark"
      :show.sync="ClienteActividad"
    >
      <CRow>
        <CCol sm="12">
         <CInput
            label="CLIENTE"
            maxlength="250"
            readonly
          />
        </CCol>
        <CCol sm="12">
          <CSelect
            label="ACTIVIDAD"
            :options="[]"
          />
        </CCol>
        <CCol sm="12" class="text-right">
          <CButton color="success">
            <CIcon name="cil-plus"/>
          </CButton>
        </CCol>
        <CCol sm="12">
          <dataTableExtended
     class="align-center-row-datatable"
            :items="items"
            :fields="fields"
            :loading="Loading"
            column-filter
            table-filter
            items-per-page-select
            :items-per-page="10"
            hover
            small
            sorter
            pagination
             
          >
            <template #Status="{item}">
              <td class="center-cell">
                <CBadge :color="getBadge(item.Status)">
                  {{item.Status}}
                </CBadge>
              </td>
            </template>
            <template #Detalle="{item}">
              <td class="center-cell">
                <CButton color="danger" square size="sm" v-c-tooltip="'Eliminar'" @click="ConfirmarEliminado(item)" >
                  <CIcon name="cil-minus" />
                </CButton>
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>
      <template #footer>
        <CButton outline color="success">
            <CIcon name="checkAlt"/>&nbsp; {{ $t('button.accept') }}
          </CButton>
          <CButton outline color="dark" @click="ClienteActividad= false">
            <CIcon name="cil-chevron-circle-left-alt"/>&nbsp; 
            <span class="ml-1">{{$t('button.cancel')}}</span>
          </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>

<script>
//import RolService from '@/_services/rol-service';
import UpperCase  from '@/_validations/uppercase-directive';
function data () {
  return {
    items: icliact,
    Loading: false,
    tituloModal: null,
    ClienteActividad: false
  }
}

const fields = [
  { 
    key: 'Fila', 
    label: '#',
    filter: false,
    _style: 'width:1%; text-align:center;',
  },
  { key: 'Cliente', label: this.$t('label.client'), _classes:'text-uppercase text-center center-cell' },
  { key: 'Usuario', label: this.$t('label.user'), _classes:'text-uppercase text-center center-cell' },
  { key: 'Fecha', label: this.$t('label.date'), _classes:'text-center center-cell' },
  { key: 'Status', label: this.$t('label.status'), _classes:'text-center center-cell' },
  { 
    key: 'Detalle',
    label: '',
    sorter: false,
    filter: false,
    _style: 'width:1%; ',_classes:'text-center center-cell'
  }
];

const icliact = [
  { Fila: '1', Cliente: 'H LEON', Usuario: 'admin', Fecha: '29/12/2020', Status: 'ACTIVO' }
]

//FUNCIONES DE METHOD
function ConfirmarEliminado(valor) {
  this.$swal.fire({
    text: `Confirmar Eliminado de Actividad: ${valor.Cliente}`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'CONFIRMAR',
    cancelButtonText: 'CANCELAR'
  }).then((result) => {
    if (result.isConfirmed) {
      
    }
  });  
}

function getBadge(status) {
  switch (status) {
    case 'ACTIVO': return 'success'
    case 'INACTIVO': return 'danger'
  }
}

export default {
  name: 'cliente-actividad-modal',
  data,
  directives: UpperCase,
  props: {
    fields: {
      type: Array,
      default () {
        return fields
      }
    },
    ValorClienteActividad: null
  },
  methods: {
    ConfirmarEliminado,
    getBadge
  },
  watch: {
    ValorClienteActividad: function () {
      if (this.ValorClienteActividad) {
        this.ClienteActividad = true;
        this.tituloModal="ACTIVIDADES DEL CLIENTE: "+this.ValorClienteActividad.Cliente;
        this.$emit('cerrarMCActividad');
      }
    }
  }
}
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>